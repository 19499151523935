body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.md5text, .shatext {
  margin-left: 5px;
  font-size: 10px;
  width: calc(100% - 45px);
  text-overflow: ellipsis;
  background-color: transparent;
  border: 0px solid black;
  padding: 0px;
  font-family: monospace;
}

.shatext.shatext {
  width: calc(100% - 65px);
}

.md5text:hover, .shatext:hover {
  background-color: white;
}

.screen_container {
  width: 600px;
}

.screen_thumb {
  width: 300px;
}

.screen_thumb {
  padding: 5px;
  box-sizing: border-box;
  max-width: 100%;
}