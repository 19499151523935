:root {
  color-scheme: light dark;
  --searchColor: darkslategray;
  --footerTextColor: #111;
  --menuColor: #333;
  --dropDownColor: #555;
  --buttonColor: #777;
  --buttonBgColor: #112;
  --footerBackgroundColor: #eee;
  --dropDownBgColor: #ddd;
  --secondaryButtonColor: #ccc;
  --dlButtonColor: darkred;
}

:root {
  --main-background: white;
  --text-color: black;
}

p a {
  font-weight: 600;
  color: var(--footerTextColor);
}

@media (prefers-color-scheme: dark) {
  :root {
    --searchColor: #eee;
    --footerTextColor: #eef;
    --menuColor: #ddd;
    --dropDownColor: #ddd;
    --buttonColor: #ccc;
    --buttonBgColor: #eee;
    --footerBackgroundColor: #112;
    --dropDownBgColor: #555;
    --secondaryButtonColor: #777;
    --dlButtonColor: rgb(239, 72, 72);
  }

  :root {
    --main-background: black;
    --text-color: white;
  }
}

body {
  background-color: var(--main-background);
  color: var(--text-color);
}

body, html, #root, .main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
.tootipWidth {
  max-width:256px;
  word-wrap:break-word;
}

#searchBox {
  width: 60%;
  color: var(--searchColor);
  font-size: 20px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ccc;
  border-radius: 25px;
  margin: 10px;
}

.copyright {
  text-decoration: none;
}
.copyright:link, .copyright:hover, .copyright:active, .copyright:visited {
  color:var(--footerTextColor);
}

.creditsContainer img:not(.avatar) {
  width: 20px;
  height: 20px;
  padding: 2px;
  background-color: white;
  border-radius: 5px;
  vertical-align: middle;
  margin-right: 5px;
  margin-left: 5px;
}

.creditsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.creditsContainer h4 {
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 2px;
  margin-left: 5px;
  color: var(--footerTextColor);
  display: block;
  /*flex clear*/
  width: 100%;

}

.creditsContainer .singleCredit {
  width: 225px;
  display: flex;
  flex-direction: row;
  /* display: flex; */
}


.creditsContainer .singleCredit .socials {
  display: flex;
  flex-direction:column;
  padding: 5px;
  font-size: 16px;
  flex-wrap: nowrap;
  vertical-align: middle;
}

.socials span {
  font-weight: 600;
  font-size: 20px;
  margin-top: -5px;
  margin-bottom: 2px;
  margin-left: 5px;
  color: var(--footerTextColor);

}

.socials a {
  text-decoration: none;
  color: var(--footerTextColor);
  font-weight: unset;
}

.copyleft {
  display: inline-block;
  transform: rotate(180deg);
}

.Footer {
  padding: 10px;
  background-color: var(--footerBackgroundColor);
  height: 20px;
  padding-left: 40px;
  padding-right: 40px;
  width: calc(100% - 80px);
  position: fixed;
}

.nav {
  position: fixed;
  z-index: 999;
}

.nav ul {
  position: fixed;
  top: 0;
  width: calc(100%);
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: var(--footerBackgroundColor);
  padding-bottom: 5px;
}

.nav li:not(#title) {
  float: right;
}

.nav li:nth-child(2) {
  padding: 0 40px 0 0;
}

.nav li a:not(#title) {
  display: block;
  color:var(--footerTextColor);
  text-align: center;
  padding: 7px 7px;
  text-decoration: none;
  border: 2px solid var(--footerBackgroundColor);
}

#title {
  text-decoration: none;
  color: var(--footerTextColor);
}

li a:hover {
  border-bottom-color: var(--footerTextColor);
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  background-color: var(--footerBackgroundColor); 
  color: var(--footerTextColor);
  font-size: inherit;
  margin: 0;
  overflow: hidden;
  padding-top: 2px;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
  border-radius: 2px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}

select:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 1px var(--dropDownColor);
  box-shadow: 0 0 0 2px -moz-mac-focusring;
  color: #222; 
  outline: none;
}

table.donationList {
  border-collapse: collapse;
  width: 100%;
  font-weight: 600;
}
/* 
table.donationList a:hover {
  color: var(--dropDownBgColor);
} */

table.donationList td, table.donationList th {
  border: 1px solid var(--footerBackgroundColor);
  padding: 8px;
}

/* table.donationList tr:nth-of-type(odd) td:nth-of-type(odd){
  background-color: var(--footerBackgroundColor);
}

table.donationList tr:nth-of-type(even) td:nth-of-type(even){
  background-color: var(--footerBackgroundColor);
} */

table.donationList a {
  text-decoration: none;
  color: var(--footerTextColor);
}

table.appAuthorList {
  border-collapse: collapse;
  width: 100%;
}

table.appAuthorList img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
  margin-left: 10px;
}

  table.appAuthorList td, table.appAuthorList th {
    border: 1px solid var(--footerBackgroundColor);
    padding: 8px;
  }

  table.appAuthorList tr:nth-child(even){
    background-color: var(--footerBackgroundColor);
  }

  table.appAuthorList td:first-child a[href] {
    font-weight: 600;
  }
  
  table.appAuthorList a {
    text-decoration: none;
    color: var(--footerTextColor);
    font-weight: unset;
  }

  select:hover {
    border-color: #888;
  }

  select option {
    background-color: var(--dropDownColor);
    color: var(--footerBackgroundColor);
  }

  .menuselect {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -webkit-padding-end: 20px;
    -moz-padding-end: 20px;
    -webkit-padding-start: 2px;
    -moz-padding-start: 2px;
    background-color: var(--footerBackgroundColor); 
    color: var(--menuColor);
    font-size: 13px;
    margin: 0;
    overflow: hidden;
    padding-top: 7px;
    padding-bottom: 7px;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: 2px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
  }
  
    .menuselect option {
      background-color: var(--dropDownColor);
      color: var(--footerBackgroundColor);
    }

.left {
  float: left;
}

.right {
  float: right;
}

.main {
  display: flex;
}

.infoBox a {
  text-decoration: none;
  text-align: center;
}

.title {
  float:left;
  padding: 10px 0 0 15px;
  font-weight: 600;
}

a.platChooser, a.platChooser h3 {
  text-decoration: none;
  color: var(--footerTextColor);
  font-weight: unset;
  margin-top: 15px;
}

.platform {
  font-weight: 200;
}

.Sidebar {
  /* background-color: rgb(30, 30, 30); */
  background-color: #2d2649;
  color: white;
  font-size: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 265px;
  position: fixed;
  margin-top: 41px;
  height: calc(100% - 110px);
  overflow: scroll;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.Sidebar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.Sidebar a {
  color: white;
  text-decoration: none;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  padding: 12px;
  padding-left: 50px;
}

.Sidebar a:hover, .Sidebar a.active {
  /* background-color: rgb(67, 67, 67); */
  background-color: #524B6E;
}

/* wiiu coloring */
.Sidebar._wiiu_only {
  background-color: #108AB7;
}

.Sidebar._wiiu_only a:hover, .Sidebar._wiiu_only a.active {
  background-color: #35AFDC;
}

.Sidebar._wiiu_only .sidebar-item.selected {
  border-left: 5px solid white;
  padding-left: 45px;
  background-color: #35AFDC;
}

/* button, .infoBox.wiiu_only a {
  background-color: #108AB7;
}
button:hover, .infoBox.wiiu_only a:hover {
  background-color: #35AFDC;
} */

.Sidebar._switch_only {
  background-color: #d62032;
}

.Sidebar._switch_only a:hover, .Sidebar._switch_only a.active {
  background-color: #fb4557;
}

.Sidebar._switch_only .sidebar-item.selected {
  border-left: 5px solid white;
  padding-left: 45px;
  background-color: #fb4557;
}

/* button, .infoBox.switch_only a {
  background-color: #d62032;
}
button:hover, .infoBox.switch_only a:hover {
  background-color: #fb4557;
} */


.Sidebar._3ds_only {
  background-color: rgb(30, 30, 30);
}

.Sidebar._3ds_only a:hover, .Sidebar._3ds_only a.active {
  /* background-color: #d62032; */
  background-color: rgb(67, 67, 67);
}

.Sidebar._3ds_only .sidebar-item.selected {
  border-left: 5px solid white;
  padding-left: 45px;
  /* background-color: #d62032; */
  background-color: rgb(67, 67, 67);
}

.icon_wrapper {
  height: 150px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Sidebar a .icon {
  width: 40px;
  font-size: 22px;
  display: inline-block;
}

.AppList {
  padding-top: 5px;
}

.AppList, .AppDetails {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.Sidebar ~ div {
  margin-left: 265px;
  margin-top: 41px;
}

.Sidebar ~ div::after {
  padding-bottom: 500px;
  content: "";
}

.AppCardWrapper {
  text-decoration: none;
}

.AppCard {
  margin: 4px;
  width: 256px;
  flex-direction: row;
  flex-grow: 1;
  color: var(--menuColor);
  margin: 5px;
  margin-bottom: 50px;
  position: relative;
}

.AppCard img {
  max-height: 150px;
  max-width: 256px;
}

.AppCard img:hover {
  filter: saturate(2);
  opacity: 0.7;
}

.AppCard__Title {
  font-weight: 700;
}

.AppCard .right {
  text-align: right;
}

#console {
  max-width: 20px;
  max-height: 20px;
  position: absolute;
  top: 5px;
  left: 5px;
}

.cardright {
  float:right;
  text-align: right;
}

.Footer {
  bottom: 0;
}

.Header svg {
  width: 15px;
  padding-right: 8px;
}

.catTitle {
  font-weight: 400;
  width: 98.5%;
  flex: 0 1 100%;
  font-size: 25px;
  padding: 20px 0 20px 40px;
  display: block;
  float:left;
}

.Ad {
  flex: 0 1 100%;
  padding: 20px 0 20px 40px;
  display: block;
  margin: 10px;
  margin-bottom: 0px;
  padding: 0;
}

.AppList .right {
  padding-right: 40px;
}

button, .infoBox a, .smallLinkButton {
  background-color: var(--dropDownBgColor);
  color: var(--buttonBgColor);
  border: none;
  padding: 10px;
  font-size: 13px;
  margin-left: 10px;
  font-weight: 500;
  cursor: pointer;
}
.smallLinkButton {
  margin-top: 10px;
  display: inline-block;
  text-decoration: none;
}

button:hover, .infoBox a:hover, .smallLinkButton:hover {
  background-color: var(--secondaryButtonColor);
}

.catTitle .sort, .catTitle .lesser {
  font-size: 15px;
  color: var(--buttonColor);
}

.AppDetailsInner {
  max-width: 848px;
}

.overlay {
  margin-top:80px;
  position: relative;
}

.AppDetails .banner {
  width: auto;
  position: relative;
  display: block;
}

.details {
  padding-bottom: 10px;
  content: "";
}

.AppDetails .details {
  white-space: pre-line;
  width: 600px;
  margin-left: 5px;
  line-height: 1.2;
  min-height: 100px;
  max-width: 600px;
}

.AppDetails .catTitle {
  padding-left: 10px;
}

.row {
  flex-direction: row;
}

.sideHeader {
  color: var(--dlButtonColor);
  font-weight: 500;
}

.infoBox {
  width: 195px;
  margin: 15px 0 15px 15px;
  box-sizing: border-box;
}

.infoBox .row {
  background-color: var(--footerBackgroundColor);
  padding: 10px 10px 10px 10px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.infoBox span {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 10px;
}

.infoBox button {
  width: 100%;
}

.infoBox button, .infoBox a {
  margin-top: 10px;
  margin-left: 0px;
  display: block;
  font-size: 25px;
}
.menu {
  display:block;
  float: right;
  margin-right: 40px;
}

.menuspan {
  display:inline-block;
}
.mobile,.mobilebr, #mobileonly{
  display: none;
}
#full {
  display: unset;
}

.tooltip {
  background-color: var(--footerBackgroundColor);
  max-width : 400px;
}
.tooltip img {
  width: 100%;
}

.tooltip button {
  margin: 5px 0 5px 5px;
}

.tooltipButtons {
  display: inline-block;
}

.tooltipButtons button {
  float: left;
}

.tooltipButtons img {
  float: right;
  max-width: 20%;
}

.tooltip img:hover {
  filter: saturate(2);
  opacity: 0.7;
}


.modal_screen {
  width: 800px;
  cursor:zoom-out;
}
.screen_thumb {
  cursor:zoom-in;
}

.sidebar-item.selected {
  border-left: 5px solid white;
  padding-left: 45px;
  /* background-color: #666; */
  background-color: #524B6E;
}

.Sidebar .text, .sidebar-item {
  color: white;
}

details summary h3 {
  display: inline;
  margin-left: 5px;
}
details summary {
  cursor: pointer;
  margin: 15px;
  margin-left: 0px;
  margin-top: 20px;
}

.promptSnippet {
  /* text area for code snippets with terminal prompt prefix */
  font-family: monospace;
  background-color: #eee;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 15px;
  color: black;
  overflow: scroll;
}

.promptSnippet::before {
  content: "$ ";
}

.responseSnippet {
  width: 100%;
  background-color: #eee;
  padding: 10px;
  border-radius: 10px;
  overflow: scroll;
  height: 250px;
  font-size: 12px;
  color: black;
}

/*Mobile Display and Landscape display*/
@media only screen and (max-width: 800px) {
  .Header .right, .Footer .right {
    display: none;
  }

  .sidebar-item.selected {
    padding-left: 20px;
  }

  li:nth-child(2) {
    padding: 0 0 0 0;
  }

  .Sidebar .text {
    display: none;
  }

  .Sidebar {
    width: 80px;
  }

  .Sidebar ~ div {
    margin-left: 80px;
  }

  .Sidebar a {
    padding-left: 25px;
  }

  .AppDetails {
    padding: 5px 20px 20px 20px;
  }

  .AppDetails .infoBox {
    width: 100%;
    margin-right: 0px;
  }

  .AppDetails .banner {
    width: 100%;
    height: unset;
  }

  .AppDetails .details {
    width: 100%;
    margin-left: 0px;
  }

  .screen_container {
    width: 100%;
  }

  .screen_thumb {
    width: unset;
  }
  .modal_screen {
    width: 100%;
  }

}

#homeBlurb {
  max-width: 1100px;
}

#homeBlurb img {
  vertical-align: middle;
  width: 64px;
}

#homeBlurb span {
  vertical-align: middle;
  font-size: 40px;
}

.pNormalWidth {
  width: 700px;
}

/*Filters for Mobile Portrait*/
@media only screen and (max-width: 600px) {
  .Sidebar {
    width:0;
  }
  .creditsContainer .singleCredit {
    width: 100%;
    margin-left: 20px;
    margin-bottom: 10px;
  }

  #platChooserList {
    flex-direction: column;
  }

  p, #homeBlurb {
    max-width: 100%;
    margin: 20px;
  }

  .pNormalWidth {
    width: 300px;
    margin: 20px;
  }

  table.donationList {
    font-size: 10px;
  }

  table:not(.donationList) {
    margin-left: -25px; /* offsets margin for full width table on mobile */
    width: 100%;
  }
  table:not(.donationList) td {
    border: none;
  }

  #homeBlurb img {
    width: 32px;
  }

  #homeBlurb span {
    font-size: 20px;
  }

  #dns,#account,#twitter,#discord,#ftu,#submit {
    display:none;
  }

  .Sidebar ~ div {
    margin-left: 0;
    margin-top: 41px;
  }

  .menuselect {
    display: unset;
    margin:0 10px 0 10px;
  }

  button, .infoBox a {
    margin-right: 1px;
  }

  .menuspan, .mobilebr, #mobileonly{
    display:unset;
  }

  .mobile {
    display : block;
    width:100%;
  }
  .categories {
    float: left;
  }
  .links {
    float: right;
  }
  .mobilemenu {
    width: 100%;
  }
  .AppDetails {
    padding: 5px 10px 20px 10px;
  }

}

#bannerWrapper {
  text-align: center;
}

.filesArea {
  margin-top: 10px;
}

.warningNotice {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  margin: 30px;
}

.warningNotice .fa-warn {
  color: #a94442;
  font-size: 40px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;
}